import React from 'react';
import { css } from '@emotion/react';

const customCss = css`
  .user-info-title {
    color: #004951 !important;
  }
`;

const LoyaltyWidget = () => {
  if (!process.env.NEXT_PUBLIC_DOTERS_ENABLED) {
    return null;
  }

  return (
    <div
      css={customCss}
      widget="login"
      data-prop-sandbox="false"
      data-prop-button-text="Regístrate"
      data-prop-hidden=""
      data-prop-invitation-text="¡Cada Punto Doters cuenta, empieza a usarlos ya!"
      data-prop-modifier-class="header"
      data-prop-origin="https://one-api.roll-bits.com"
      data-prop-profile-option="false"
      data-prop-variant="link"
      data-prop-recover-password="false"
      data-prop-register-account="false"
      data-prop-third-party="false"
      data-prop-primary-color="#004951"
      data-prop-primarystrong-color="#003238"
      data-prop-accent-color="#2AFFAC"
      data-prop-header-color="true"
      data-prop-sign-in-title="Ingresa y acumula puntos"
      data-prop-privacy-url="https://contenido.roll-bits.com/terminosycondiciones/#doters"
      data-prop-terms-and-conditions-url="https://contenido.roll-bits.com/terminosycondiciones/#doters"
      data-prop-icon-type="doters"
      data-prop-card-type="doters"
      data-prop-modal-description="Inicia sesión para seguir acumulando puntos en todas tus compras para seguir viajando más"
      data-prop-modal-logo="true"
      data-prop-logout-api="false"
      data-prop-mixpanel-prefix="ROLL-AND-BITS"
      data-prop-link-text=""
      data-prop-hide-icon="false"
      data-prop-button-logo="doters"
      data-prop-cookie-expiration="7"
      data-prop-on-sidebar="false"
      data-prop-sso-host={`${process.env.NEXT_PUBLIC_DOTERS_AUTH_URL}`}
      data-prop-sso-client-secret={`${process.env.NEXT_PUBLIC_CLIENT_SECRET}`}
      data-prop-sso-logout-host={`${process.env.NEXT_PUBLIC_DOTERS_LOGOUT_URL}`}
      data-prop-sso-redirect-key-logout="RESERVAMOS_REDIRECT_SSO_LOGOUT_KEY"
      data-prop-sso-redirect-key-login="RESERVAMOS_REDIRECT_SSO_LOGIN_KEY"
      data-prop-sso-client-id={`${process.env.NEXT_PUBLIC_CLIENT_ID}`}
      data-prop-sso-login-redirect-uri={`${process.env.NEXT_PUBLIC_DOTERS_REDIRECT_URL}`}
      data-prop-sso-logout-redirect-uri="https://viajes.roll-bits.com/sso/logout"
      data-prop-link-the-program="https://roll-bits.com/doters"
      data-prop-show-signin-text="true"
      data-prop-show-mobile-icon="true"
    />
  );
};

export default LoyaltyWidget;
